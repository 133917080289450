import { Action, createReducer, on } from '@ngrx/store';
import { ResponseWrapper } from 'common';
import * as AppActions from './actions';
import { foldersReducer } from './folders/folders.reducer';
import { AudienceBuilderState, CurrentOnQFoldersResponse, Group, IssueBuilderFolder, LibraryFolder, SelectionCriteria, Set } from './interfaces';

const initalAppState: AudienceBuilderState = {
    seachQueriesResults: {
        loading: false,
        data: {
            listSize: null,
            resultList: []
        }
    },
    searchOutputLayoutsResults: {
        loading: false,
        data: null
    },
    currentSelectionCriteria: {
        loading: false,
        data: null
    },
    foldersContent: {
        loading: false,
        data: null
    },
    currentFolders: {
        loading: false,
        etag: null,
        data: null
    },
    //TODO Possibly redundant and definitely confusing, investigate
    contactRules: {
        loading: false,
        data: null
    },
    contactRulesLHS: {
        loading: false,
        etag: null,
        data: {
            postal: [],
            shippingProduct: [],
            billingProduct: [],
            email: [],
            emailProduct: [],
            phone: [],
            messageTypePhone: [],
            fax: [],
        }
    },
    sessionContactRules: {
        loading: false,
        data: []
    },
    recentBuilds: {
        loading: false,
        data: null
    },
    isRebuildInProgress: {
        loading: false,
        data: {
            inProgress: false
        }
    },
    isRefreshInProgress: {
        loading: false,
        data: {
            inProgress: false
        }
    },
    searchExistingDeploymentsResult: {
        loading: false,
        data: {
            deployments: []
        }
    },
    outputData: {
        loading: false,
        data: {
            segmentFolderSchemaList: [],
            multiResponseDelimiterList: [],
            outputCriteriaList: [],
            paidProductSchemaList: [],
            groupList: [],
            outputFieldHeaders: [],
            existingSegmentList: [],
            standardOutputSchema: {
                outputFieldList: []
            }
        }
    },
    matchEvaluationData: {
        loading: false,
        data: {
            textFieldList: []
        }
    },
    issueBuilderFoldersMap: {
        loading: false,
        data: null
    },
    issueBuildsList: {
        loading: false,
        data: []
    },
    issueCloseList: {
        loading: false,
        data: null,
    },
    issueBuildCounts: {
        loading: false,
        data: null
    },
    previewIssueBuildData: {
        loading: false,
        data: {
            previewIssueBuildList: []
        }
    },
    currentSessionGroups: [],
    productsList: {
        loading: false,
        data: []
    },
    issueList: {
        loading: false,
        data: []
    },
    mailingList: {
        loading: false,
        data: []
    },
    saveRuleData: {
        loading: false,
        data: null
    },
    audienceCounts: {
        loading: false,
        data: null
    },
    outputDeploymentTypes: {
        loading: false,
        data: []
    },
    nthGroupsOff: true,
    isInventoryCalendarAllowed: true,
    nthGroupsShow: false,
    currentQueriesPaths: null,
    queryLoader: false,
    smallQueryLoader: false,
    saveQueryLoader: false,
    selectedDemographicList: {
        loading: false,
        data: []
    },
    filtratedIndividualBehaviors: null,
    filtratedProductBehaviors: null
};

function getInitalState(): AudienceBuilderState {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const savedState = getSavedState();
    // if (savedState) {
    //     return savedState;
    // } else {
    return initalAppState;
    // }
}

function getSavedState(): AudienceBuilderState {
    const savedState = sessionStorage.getItem('appState');
    if (savedState) {
        return JSON.parse(savedState);
    }
    return null;
}

const appReducer = createReducer(
    getInitalState(),
    on(AppActions.GetIsInventoryCalendarAllowedSuccess, (state) => {
        return {
            ...state,
            isInventoryCalendarAllowed: true
        };
    }),
    on(AppActions.GetIsInventoryCalendarAllowedError, (state) => {
        return {
            ...state,
            isInventoryCalendarAllowed: false
        };
    }),
    on(AppActions.OpenIssueBuild, (state) => {
        return {
            ...state,
            queryLoader: true
        };
    }),
    on(AppActions.OpenIssueBuildSuccess, (state) => {
        let foldersContent = {...state?.foldersContent.data};
        if (foldersContent) {
            Object.keys(foldersContent).forEach(key => {
                Object.keys(foldersContent[key]).forEach(innerKey => {
                    foldersContent = {...foldersContent, [key]: {...foldersContent[key], [innerKey]: {...foldersContent[key][innerKey], selection: null, loading: false, initialLoaded: false}}};
                });
            });
        }
        return {
            ...state,
            queryLoader: false,
            currentSelectionCriteria: {
                loading: false,
                data: null
            },
            foldersContent: {
                loading: false,
                data: foldersContent
            },
            calculatedCopyCounts: null,
            selectedInventoryCalendarDay: null,
        };
    }),
    on(AppActions.OpenIssueBuildError, (state) => {
        return {
            ...state,
            queryLoader: false
        };
    }),
    on(AppActions.OpenIssueRuleFromRoute, (state) => {
        return {
            ...state,
            queryLoader: true
        };
    }),
    on(AppActions.OpenIssueRuleFromRouteError, (state) => {
        return {
            ...state,
            queryLoader: false
        };
    }),
    on(AppActions.ApproveIssueBuild, (state) => {
        return {
            ...state,
            previewIssueLoading: true
        };
    }),
    on(AppActions.ApproveIssueBuildSuccess, (state) => {
        return {
            ...state,
            previewIssueLoading: false
        };
    }),
    on(AppActions.ApproveIssueBuildError, (state) => {
        return {
            ...state,
            previewIssueLoading: false
        };
    }),
    on(AppActions.ExitIssueBuilder, (state) => {
        return {
            ...state,
            issueBuildCounts: {
                loading: false,
                data: null
            },
            queryLoader: true
        };
    }),
    on(AppActions.ExitIssueBuilderSuccess, (state) => {
        return {
            ...state,
            queryLoader: false
        };
    }),
    on(AppActions.ExitIssueBuilderError, (state) => {
        return {
            ...state,
            queryLoader: false
        };
    }),
    //TODO add general issue list loader
    on(AppActions.DeleteIssueBuild, (state) => {
        return {
            ...state,
            issueBuildsList: {
                loading: true,
                data: state.issueBuildsList?.data
            }
        };
    }) ,
    on(AppActions.DeleteRule, (state) => {
        return {
            ...state,
            previewIssueLoading: true
        };
    }) ,
    on(AppActions.DeleteRuleSuccess, (state) => {
        return {
            ...state,
            previewIssueLoading: false
        };
    }) ,
    on(AppActions.DeleteRuleError, (state) => {
        return {
            ...state,
            previewIssueLoading: false
        };
    }) ,
    on(AppActions.GetIssueBuilderCounts, (state) => {
        return {
            ...state,
            issueBuildCounts: {
                loading: true,
                data: state.issueBuildCounts?.data
            }
        };
    }),
    on(AppActions.GetIssueBuilderCountsSuccess, (state, action) => {
        return {
            ...state,
            issueBuildCounts: action.payload
        };
    }),
    on(AppActions.GetIssueBuilderCountsError, (state) => {
        return {
            ...state,
            issueBuildCounts: {
                loading: false,
                data: null
            }
        };
    }),
    on(AppActions.GetIssueBuilderFoldersSuccess, (state, action) => {
        const ibFolders = action.payload.data;
        const currentFolders = {
            ...state.currentFolders.data
        };

        const fillMap = (folder: IssueBuilderFolder, map: Map<number, boolean>) => {
            map.set(folder.folderId, folder.issueBuildColorOn);
            if (folder.childFoldersList) {
                folder.childFoldersList.forEach(child => {
                    fillMap(child, map);
                });
            }
        };

        const ibFoldersMap = new Map();
        fillMap(ibFolders, ibFoldersMap);

        function updateColors(obj: CurrentOnQFoldersResponse, map: Map<number, boolean>): CurrentOnQFoldersResponse {
            const newObj: CurrentOnQFoldersResponse = { ...obj };
            if (map.has(obj.onqFolderId)) {
                newObj.issueBuildColorOn = map.get(obj.onqFolderId);
            }
            if (obj.folderList) {
                newObj.folderList = obj.folderList.map(folder => updateColors(folder, map));
            }
            return newObj;
        }

        const result = updateColors(currentFolders, ibFoldersMap);
        const ibFoldersMapObject = Object.fromEntries(ibFoldersMap);

        return {
            ...state,
            issueBuilderFoldersMap: {
                loading: false,
                data: ibFoldersMapObject
            },
            currentFolders: {
                loading: false,
                data: result
            }
        };
    }),
    on(AppActions.ClearIssueBuilderFoldersMap, (state) => {
        return {
            ...state,
            issueBuilderFoldersMap: {
                loading: false,
                data: null
            }
        };
    }),
    on(AppActions.SearchQueries, (state) => {
        return {
            ...state,
            seachQueriesResults: {
                loading: true,
                data: state.seachQueriesResults?.data
            }
        };
    }),
    on(AppActions.SearchQueriesSuccess, (state, action) => {
        return {
            ...state,
            seachQueriesResults: {
                loading: false,
                data: action.payload.data,
            }
        };
    }),
    on(AppActions.SearchQueriesError, (state) => {
        return {
            ...state,
            seachQueriesResults: {
                loading: false,
                data: state.seachQueriesResults?.data
            }
        };
    }),
    on(AppActions.ChangeContactRulesSuccess, (state, action) => {
        return {
            ...state,
            currentSelectionCriteria: {
                loading: false,
                data: {
                    ...action.payload.data,
                    ignoreFolderReload: true
                }
            }
        };
    }),
    on(AppActions.SetRebuildInProgress, (state, action) => {
        return {
            ...state,
            isRebuildInProgress: {
                loading: false,
                data: {
                    inProgress: action.payload
                }
            }
        };
    }),
    on(AppActions.CheckRebuildProgress, (state) => {
        return {
            ...state,
            isRebuildInProgress: {
                loading: true,
                data: {
                    inProgress: true
                }
            }
        };
    }),
    on(AppActions.CheckRebuildProgressSuccess, (state, action) => {
        return {
            ...state,
            isRebuildInProgress: {
                loading: false,
                data: {
                    //API returns true if not in progress
                    inProgress: !action.payload.success
                }
            }
        };
    }),
    on(AppActions.CheckRebuildProgressError, (state) => {
        return {
            ...state,
            isRebuildInProgress: {
                loading: false,
                data: {
                    //TODO should work for now, but backend response should be changed
                    // when build is in progress response is an error instead of 200 inProgress: true
                    inProgress: true
                }
            }
        };
    }),
    on(AppActions.SetRefreshProfileInProgress, (state, action) => {
        return {
            ...state,
            isRefreshInProgress: {
                loading: false,
                data: {
                    inProgress: action.payload
                }
            }
        };
    }),
    on(AppActions.CheckRefreshProfileProgress, (state) => {
        return {
            ...state,
            isRefreshInProgress: {
                loading: true,
                data: {
                    inProgress: true
                }
            }
        };
    }),
    on(AppActions.CheckRefreshProfileProgressSuccess, (state, action) => {
        return {
            ...state,
            isRefreshInProgress: {
                loading: false,
                data: {
                    //API returns true if not in progress
                    inProgress: !action.payload.success
                }
            }
        };
    }),
    on(AppActions.CheckRefreshProfileProgressError, (state) => {
        return {
            ...state,
            isRefreshInProgress: {
                loading: false,
                data: {
                    //TODO should work for now, but backend response should be changed
                    // when build is in progress response is an error instead of 200 inProgress: true
                    inProgress: true
                }
            }
        };
    }),
    on(AppActions.GetRecentBuilds, (state) => {
        return {
            ...state,
            recentBuilds: {
                loading: true,
                data: state.recentBuilds.data
            }
        };
    }),

    on(AppActions.ClearRecentBuilds, (state) => {
        return {
            ...state,
            recentBuilds: {
                loading: false,
                data: null
            }
        };
    }),

    on(AppActions.GetRecentBuildsSuccess, (state, action) => {
        return {
            ...state,
            recentBuilds: {
                loading: false,
                data: action.payload
            }
        };
    }),
    on(AppActions.GetRecentBuildsError, (state) => {
        return {
            ...state,
            recentBuilds: {
                loading: false,
                data: state.recentBuilds.data
            }
        };
    }),
    on(AppActions.SearchOutputLayouts, (state) => {
        return {
            ...state,
            searchOutputLayoutsResults: {
                loading: true,
                data: state.searchOutputLayoutsResults?.data
            }
        };
    }),
    on(AppActions.SearchOutputLayoutsSuccess, (state, action) => {
        return {
            ...state,
            searchOutputLayoutsResults: {
                loading: false,
                data: action.payload.data,
            }
        };
    }),
    on(AppActions.SearchOutputLayoutsError, (state) => {
        return {
            ...state,
            searchOutputLayoutsResults: {
                loading: false,
                data: state.searchOutputLayoutsResults?.data
            }
        };
    }),
    on(AppActions.GetOutputData, (state) => {
        return {
            ...state,
            outputData: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.GetOutputDataSuccess, (state, action) => {
        return {
            ...state,
            outputData: {
                loading: action.payload.loading,
                data: action.payload.data,
            }
        };
    }),
    on(AppActions.GetOutputDataError, (state) => {
        return {
            ...state,
            // outputData: {
            //     loading: false,
            //     data: state.outputData?.data
            // }
        };
    }),
    on(AppActions.GetMatchEvaluationData, (state) => {
        return {
            ...state,
            matchEvaluationData: {
                loading: true,
                data: state.matchEvaluationData?.data
            }
        };
    }),
    on(AppActions.GetMatchEvaluationDataSuccess, (state, action) => {
        return {
            ...state,
            matchEvaluationData: {
                loading: false,
                data: action.payload.data,
            }
        };
    }),
    on(AppActions.GetMatchEvaluationDataError, (state) => {
        return {
            ...state,
            matchEvaluationData: {
                loading: false,
                data: state.matchEvaluationData?.data
            }
        };
    }),
    //Issue Builder
    on(AppActions.GetIssueBuilds, (state) => {
        return {
            ...state,
            issueBuildsList: {
                loading: true,
                data: state.issueBuildsList.data
            }
        };
    }),
    on(AppActions.GetIssueCloseList, (state) => {
        return {
            ...state,
            issueCloseList: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.GetIssueCloseListSuccess, (state, action) => {
        return {
            ...state,
            issueCloseList: {
                loading: false,
                data: action.payload.data
            }
        };
    }),
    on(AppActions.GetIssueCloseListError, (state) => {
        return {
            ...state,
            issueCloseList: {
                loading: false,
                data: state.issueCloseList.data
            }
        };
    }),
    on(AppActions.GetIssueBuildsSuccess, (state, action) => {
        return {
            ...state,
            issueBuildsList: {
                loading: false,
                data: action.payload.data
            }
        };
    }),
    on(AppActions.GetIssueBuildsError, (state) => {
        return {
            ...state,
            issueBuildsList: {
                loading: false,
                data: state.issueBuildsList.data
            }
        };
    }),
    on(AppActions.PreviewIssueBuild, (state) => {
        return {
            ...state,
            previewIssueBuildData: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.PreviewIssueBuildSuccess, (state, action) => {
        return {
            ...state,
            previewIssueBuildData: {
                loading: false,
                data: action.payload.data
            }
        };
    }),
    on(AppActions.PreviewIssueBuildError, (state) => {
        return {
            ...state,
            previewIssueBuildData: {
                loading: false,
                data: null
            }
        };
    }),
    on(AppActions.LoadSaveRuleData, (state) => {
        return {
            ...state,
            saveRuleData: {
                loading: true,
                data: null,
            }
        };
    }),
    on(AppActions.LoadSaveRuleDataSuccess, (state, action) => {
        return {
            ...state,
            saveRuleData: action.payload
        };
    }),
    on(AppActions.LoadSaveRuleDataError, (state) => {
        return {
            ...state,
            saveRuleData: {
                loading: false,
                data: null
            }
        };
    }),
    on(AppActions.SaveRuleAuditTypes, (state) => {
        return {
            ...state,
            saveRuleAuditTypes: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.SaveRuleAuditTypesSuccess, (state, action) => {
        return {
            ...state,
            saveRuleAuditTypes: {
                loading: false,
                data: action.payload.data
            },
            saveRuleData: {
                ...state.saveRuleData,
                data: {
                    ...state.saveRuleData?.data,
                    auditTypes: action.payload.data
                }
            }
        };
    }),
    on(AppActions.SaveRuleAuditTypesError, (state) => {
        return {
            ...state,
            saveRuleAuditTypes: {
                loading: false,
                data: {...state?.saveRuleAuditTypes?.data}
            }
        };
    }),
    on(AppActions.SaveRuleNthFieldData, (state) => {
        return {
            ...state,
            saveRuleNthFields: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.SaveRuleNthFieldDataSuccess, (state, action) => {
        return {
            ...state,
            saveRuleNthFields: {
                loading: false,
                data: action.payload.data
            }
        };
    }),
    on(AppActions.SaveRuleNthFieldDataError, (state) => {
        return {
            ...state,
            saveRuleNthFields: {
                loading: false,
                data: state.saveRuleNthFields?.data
            }
        };
    }),
    on(AppActions.SaveRule, (state) => {
        return {
            ...state,
            saveRuleData: {
                loading: true,
                data: state.saveRuleData.data
            }
        };
    }),
    on(AppActions.SaveRuleSuccess, (state) => {
        return {
            ...state,
            saveRuleData: {
                loading: false,
                data: state.saveRuleData.data
            }
        };
    }),
    on(AppActions.SaveRuleError, (state) => {
        return {
            ...state,
            saveRuleData: {
                loading: false,
                data: state.saveRuleData.data
            }
        };
    }),
    //TODO unify handling when updating current selection criteria
    on(AppActions.GetAudienceCountsSuccess, (state, action) => {
        return {
            ...state,
            audienceCounts: action.payload
        };
    }),

    on(AppActions.GetDistinctEmails, (state) => {
        return {
            ...state,
            audienceCounts: {
                loading: true,
                data: {
                    ...state.audienceCounts?.data,
                    distinctEmails: 'Loading...'
                }
            }
        };
    }),

    on(AppActions.GetDistinctEmailsSuccess, (state, action) => {
        return {
            ...state,
            audienceCounts: {
                loading: false,
                data: {
                    ...state.audienceCounts?.data,
                    distinctEmails: action.payload?.data?.distinctEmailCount
                }
            }
        };
    }),

    on(AppActions.GetDistinctEmailsError, (state) => {
        return {
            ...state,
            audienceCounts: {
                loading: false,
                data: {
                    ...state.audienceCounts?.data,
                    distinctEmails: null
                }
            }
        };
    }),

    on(AppActions.RemoveQueryGroup, (state) => {
        return {
            ...state,
            queryLoader: true
        };
    }),

    on(AppActions.RemoveQueryGroupSuccess, (state, action) => {
        return {
            ...state,
            currentSelectionCriteria: {
                ...state.currentSelectionCriteria,
                data: {
                    ...action.payload.data,
                    ignoreFolderReload: false
                }
            },
            queryLoader: false,
            calculatedCopyCounts: null
        };
    }),

    on(AppActions.RemoveQueryGroupError, (state) => {
        return {
            ...state,
            queryLoader: false
        };
    }),

    on(AppActions.CloneQueryGroup, (state) => {
        return {
            ...state,
            queryLoader: true
        };
    }),

    on(AppActions.CloneQueryGroupSuccess, (state, action) => {
        return {
            ...state,
            currentSelectionCriteria: {
                ...state.currentSelectionCriteria,
                data: {
                    ...action.payload.data,
                    ignoreFolderReload: false
                }
            },
            queryLoader: false
        };
    }),

    on(AppActions.CloneQueryGroupError, (state) => {
        return {
            ...state,
            queryLoader: false
        };
    }),

    on(AppActions.MoveQueryGroup, (state) => {
        return {
            ...state,
            queryLoader: true
        };
    }),

    on(AppActions.MoveQueryGroupSuccess, (state, action) => {
        return {
            ...state,
            currentSelectionCriteria: {
                ...state.currentSelectionCriteria,
                data: {
                    ...action.payload.data,
                    ignoreFolderReload: false
                }
            },
            queryLoader: false
        };
    }),

    on(AppActions.MoveQueryGroupError, (state) => {
        return {
            ...state,
            queryLoader: false
        };
    }),

    on(AppActions.GetProductsListSuccess, (state, action) => {
        return {
            ...state,
            productsList: {
                loading: false,
                data: action.payload.data
            }
        };
    }),
    on(AppActions.GetMailingList, (state) => {
        return {
            ...state,
            mailingList: {
                loading: true,
                data: []
            }
        };
    }),
    on(AppActions.GetMailingListSuccess, (state, action) => {
        return {
            ...state,
            mailingList: {
                loading: false,
                data: action.payload.data
            }
        };
    }),
    on(AppActions.GetMailingListError, (state) => {
        return {
            ...state,
            mailingList: {
                loading: false,
                data: []
            }
        };
    }),

    on(AppActions.UploadExternalList, (state) => {
        return {
            ...state,
            queryLoader: true
        };
    }),

    on(AppActions.UploadExternalListSuccess, (state, action) => {
        return {
            ...state,
            currentSelectionCriteria: {
                ...state.currentSelectionCriteria,
                data: {
                    ...state.currentSelectionCriteria.data,
                    query: action.payload.query,
                    ignoreFolderReload: true
                }
            },
            queryLoader: false
        };
    }),

    on(AppActions.UploadExternalListError, (state) => {
        return {
            ...state,
            queryLoader: false
        };
    }),

    on(AppActions.ClearOutputData, (state) => {
        return {
            ...state,
            outputData: {
                loading: false,
                data: {
                    segmentFolderSchemaList: [],
                    multiResponseDelimiterList: [],
                    outputCriteriaList: [],
                    paidProductSchemaList: [],
                    groupList: [],
                    outputFieldHeaders: [],
                    existingSegmentList: [],
                    standardOutputSchema: {
                        outputFieldList: []
                    }
                }
            }
        };
    }),
    on(AppActions.GetFields, (state) => {
        return {
            ...state,
            fieldCounts: {
                loading: true,
                data: state.fieldCounts?.data
            }
        };
    }),
    on(AppActions.GetFieldsSuccess, (state, action) => {
        return {
            ...state,
            fieldCounts: {
                loading: false,
                data: action.payload.data,
            }
        };
    }),
    on(AppActions.GetFieldsError, (state) => {
        return {
            ...state,
            fieldCounts: {
                loading: false,
                data: state.fieldCounts?.data
            }
        };
    }),
    on(AppActions.GetOutputCriteria, (state) => {
        return {
            ...state,
            outputData: {
                loading: true,
                data: state.outputData?.data
            },
        };
    }),
    on(AppActions.GetOutputCriteriaSuccess, (state, action) => {
        return {
            ...state,
            outputData: {
                loading: false,
                data: {...state.outputData?.data, ...action.payload?.data},
            }
        };
    }),
    on(AppActions.GetOutputCriteriaError, (state) => {
        return {
            ...state,
            outputData: {
                loading: false,
                data: state.outputData?.data
            }
        };
    }),
    on(AppActions.LoadAvailableOutputField, (state, action) => {
        const outputAvailableFieldsList = Object.assign({}, state.outputAvailableFields?.data || {});

        if (outputAvailableFieldsList[action.payload.outputGroupName]) {
            outputAvailableFieldsList[action.payload.outputGroupName] = { ...outputAvailableFieldsList[action.payload.outputGroupName], loading: true };
        } else {
            outputAvailableFieldsList[action.payload.outputGroupName] = { loading: true };
        }

        return {
            ...state,
            outputAvailableFields: {
                loading: true,
                data: outputAvailableFieldsList
            }
        };
    }),
    on(AppActions.LoadAvailableOutputFieldSuccess, (state, action) => {
        const outputAvailableFieldsList = Object.assign({}, state.outputAvailableFields?.data || {});
        outputAvailableFieldsList[action.payload.outputGroupName] = {...action.payload, loading: false};
        return {
            ...state,
            outputAvailableFields: {
                loading: false,
                data: outputAvailableFieldsList
            }
        };
    }),
    on(AppActions.LoadAvailableOutputFieldError, (state, action) => {
        const outputAvailableFieldsList = Object.assign({}, state.outputAvailableFields?.data || {});
        outputAvailableFieldsList[action.payload.outputGroupName] = { loading: false };

        return {
            ...state,
            outputAvailableFields: {
                loading: false,
                data: outputAvailableFieldsList
            }
        };
    }),

    on(AppActions.CreateOutput, (state, action) => {
        let data = { ...state.outputData?.data };
        if (action.payload?.selectedFields?.length > 0) {
            data = { ...data, selectedFields: action.payload?.selectedFields };
        }
        return {
            ...state,
            createOutput: {
                loading: true,
                data: {
                    ...action.payload,
                    minute: action.payload?.minute?.toString(),
                    inventoryCalendarDeploymentType: Number(action.payload?.inventoryCalendarDeploymentType),
                }
            },
            outputData: {
                loading: true,
                data
            }
        };
    }),
    on(AppActions.CreateOutputSuccess, (state, action) => {
        return {
            ...state,
            createOutput: {
                loading: false,
                data: action.payload?.data
            },
            outputData: {
                loading: false
            }
        };
    }),
    on(AppActions.CreateOutputError, (state, error) => {
        return {
            ...state,
            outputData: {
                loading: false,
                data: state.outputData?.data,
                errors: error
            },
            createOutput: {
                loading: false,
                data: state.createOutput?.data,
                errors: error
            }
        };
    }),

    on(AppActions.CreateOutputWithoutQueue, (state, action) => {
        let data = { ...state.outputData?.data };
        if (action.payload?.selectedFields?.length > 0) {
            data = { ...data, selectedFields: action.payload?.selectedFields };
        }
        return {
            ...state,
            createOutput: {
                loading: true,
                data: {
                    ...action.payload,
                    minute: action.payload?.minute?.toString(),
                    inventoryCalendarDeploymentType: Number(action.payload?.inventoryCalendarDeploymentType),
                }
            },
            outputData: {
                loading: true,
                data
            }
        };
    }),
    on(AppActions.CreateOutputWithoutQueueSuccess, (state, action) => {
        return {
            ...state,
            createOutput: {
                loading: false,
                data: action.payload?.data
            },
            outputData: {
                loading: false
            }
        };
    }),
    on(AppActions.CreateOutputWithoutQueueError, (state, error) => {
        return {
            ...state,
            outputData: {
                loading: false,
                data: state.outputData?.data,
                errors: error
            },
            createOutput: {
                loading: false,
                data: state.createOutput?.data,
                errors: error
            }
        };
    }),

    on(AppActions.ClearCreateOutput, (state) => {
        return {
            ...state,
            createOutput: {
                loading: false,
                data: null
            }
        };
    }),

    on(AppActions.GetDemoValues, (state) => {
        return {
            ...state,
            demoValues: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.GetDemoValuesSuccess, (state, action) => {
        //TODO either rework or move the full response to campaigns store where it is used
        return {
            ...state,
            demoValues: {
                loading: false,
                data: action.payload.data?.demographic?.[0]?.demographicValues
            },
            fullDemoValuesResponse: {
                loading: false,
                data: action.payload.data
            }
        };
    }),
    on(AppActions.GetDemoValuesError, (state) => {
        return {
            ...state,
            demoValues: {
                loading: false,
                data: null,
            }
        };
    }),

    on(AppActions.FieldCountReport, (state) => {
        return {
            ...state,
            fieldCountsReport: {
                loading: true,
                data: state.fieldCountsReport?.data
            }
        };
    }),
    on(AppActions.FieldCountReportSuccess, (state, action) => {
        return {
            ...state,
            fieldCountsReport: {
                loading: false,
                data: action.payload,
                success: true
            }
        };
    }),
    on(AppActions.FieldCountReportError, (state) => {
        return {
            ...state,
            fieldCountsReport: {
                loading: false,
                data: state.fieldCountsReport?.data,
                errors: true
            }
        };
    }),
    on(AppActions.ClearSearchQueriesResults, (state) => {
        return {
            ...state,
            seachQueriesResults: {
                loading: false,
                data: null
            }
        };
    }),
    on(AppActions.ClearSearchOutputLayoutsResults, (state) => {
        return {
            ...state,
            searchOutputLayoutsResults: {
                loading: false,
                data: null
            }
        };
    }),
    // on(AppActions.SetCurrentQuery, (state, action) => {
    //     const data = {...state.currentQuery.data, ...action.payload}
    //     return {
    //         ...state,
    //         currentQuery: {
    //             loading: false,
    //             source: "form",
    //             data: data
    //         }
    //     };
    // }),
    // on(AppActions.OpenQuery, (state, action) => {
    //     return {
    //         ...state,
    //         currentQuery: {
    //             loading: true,
    //             source: "loaded",
    //             data: state.currentQuery?.data
    //         }
    //     };
    // }),
    // on(AppActions.OpenQuerySuccess, (state, action) => {
    //     return {
    //         ...state,
    //         currentQuery: {
    //             loading: false,
    //             source: "loaded",
    //             data: action.payload.data,
    //         }
    //     };
    // }),
    // on(AppActions.OpenQueryError, (state, action) => {
    //     return {
    //         ...state,
    //         currentQuery: {
    //             loading: false,
    //             source: "loaded",
    //             data: state.currentQuery?.data
    //         }
    //     };
    // }),
    // on(AppActions.GetQueryHistory, (state, action) => {
    //     return {
    //         ...state,
    //         currentQueryHistory: {
    //             loading: true,
    //             data: state.currentQueryHistory?.data
    //         }
    //     };
    // }),
    // on(AppActions.GetQueryHistorySuccess, (state, action) => {
    //     return {
    //         ...state,
    //         currentQueryHistory: {
    //             loading: false,
    //             data: action.payload.data,
    //         }
    //     };
    // }),
    // on(AppActions.GetQueryHistoryError, (state, action) => {
    //     return {
    //         ...state,
    //         currentQueryHistory: {
    //             loading: false,
    //             data: state.currentQueryHistory?.data
    //         }
    //     };
    // }),
    on(AppActions.GetExistingDeployments, (state) => {
        return {
            ...state,
            searchExistingDeploymentsResult: {
                loading: true,
                data: state.searchExistingDeploymentsResult?.data
            }
        };
    }),
    on(AppActions.GetExistingDeploymentsSuccess, (state, action) => {
        return {
            ...state,
            searchExistingDeploymentsResult: {
                loading: false,
                data: action.payload.data
            }
        };
    }),
    on(AppActions.GetExistingDeploymentsError, (state) => {
        return {
            ...state,
            searchExistingDeploymentsResult: {
                loading: false,
                data: state.searchExistingDeploymentsResult?.data
            }
        };
    }),
    on(AppActions.GetCurrentOnQFolders, (state) => {
        return {
            ...state,
            currentFolders: {
                loading: true,
                etag: state.currentFolders?.etag,
                data: state.currentFolders?.data
            },
            currentFoldersFirstState: {
                loading: true,
                data: state.currentFoldersFirstState?.data,
            },
            currentQueriesPaths: null
        };
    }),
    on(AppActions.GetCurrentOnQFoldersSuccess, (state, action) => {
        let mapResult = null;
        if (!state?.currentQueriesPaths && action.payload.data.folderList && action.payload.data.folderList.length) {
            const stack: { folder; path: string }[] = [];
            const pathResult = new Object();
            const findPaths = (root) => {
                root?.forEach(innerFolder => {
                    stack.push({ folder: innerFolder, path: innerFolder.onqFolderId });

                    while (stack.length > 0) {
                        const { folder, path } = stack.pop() as { folder; path: string };

                        if (folder.type && folder.type > 10 && !folder.folderList) {
                            pathResult[folder.onqFolderId] = path.toString().split(',').map(Number);
                        }

                        if (folder.folderList) {
                            for (const subFolder of folder.folderList) {
                                const newPath = `${path},${subFolder.onqFolderId}`;
                                stack.push({ folder: subFolder, path: newPath });
                            }
                        }
                    }
                });
                return pathResult;
            };
            mapResult = findPaths(action.payload.data?.folderList);
        }
        return {
            ...state,
            currentFolders: {
                loading: false,
                etag: action.payload.etag,
                data: action.payload.data,
            },
            currentQueriesPaths: mapResult || state?.currentQueriesPaths,
            currentFoldersFirstState: {
                loading: false,
                data: action.payload.data,
            }
        };

    }),
    on(AppActions.GetCurrentOnQFoldersError, (state) => {
        return {
            ...state,
            currentFolders: {
                loading: false,
                etag: state.currentFolders?.etag,
                data: state.currentFolders?.data
            },
            currentQueriesPaths: state?.currentQueriesPaths
        };
    }),
    on(AppActions.GetAvailableOnQFolders, (state) => {
        return {
            ...state,
            availableFolders: {
                loading: true,
                data: state.availableFolders?.data
            }
        };
    }),
    on(AppActions.GetAvailableOnQFoldersSuccess, (state, action) => {
        let data = null;
        if ('availableFolderList' in action?.payload?.data) {
            data = action?.payload?.data;
        } else {
            data = { availableFolderList: [] };
        }
        return {
            ...state,
            availableFolders: {
                loading: false,
                data: data,
            }
        };
    }),
    on(AppActions.GetAvailableOnQFoldersError, (state) => {
        return {
            ...state,
            availableFolders: {
                loading: false,
                data: state.availableFolders?.data
            }
        };
    }),
    on(AppActions.GetFolderContent, (state, action) => {
        let res = {...state?.foldersContent.data};
        const onqFolderId = action.payload.folder?.columnValue || action.payload.folder?.folderValueId || action.payload.folder?.onqFolderId;
        const activeGroup = action.payload.activeGroup;
        if (res?.[activeGroup] && res?.[activeGroup]?.[onqFolderId]) {
            res = {...res, [activeGroup]: {...res[activeGroup], [onqFolderId]: {...res[activeGroup][onqFolderId], loading: true }}};
        } else if (res?.[activeGroup]) {
            res = {...res, [activeGroup]: {...res[activeGroup], [onqFolderId]: { loading: true }}};
        } else {
            res = {...res, [activeGroup]: {[onqFolderId]: { loading: true }}};
        }
        return {
            ...state,
            foldersContent: {
                loading: true,
                data: res
            },
        };
    }),
    on(AppActions.DeleteFolderSelection, (state, action) => {
        let res = {...state?.foldersContent.data};
        const id = action.folderId ? action.folderId : action.folderValueId;
        const activeGroup = action?.activeGroup;
        if (res?.[activeGroup]?.[id] && res?.[activeGroup]?.[id]?.selection) {
            res = {...res, [activeGroup]: {...res[activeGroup], [id]: {...res[activeGroup][id], selection: null, loading: false, initialLoaded: false}}};
        }
        return {
            ...state,
            foldersContent: {
                loading: false,
                data: res
            }
        };
    }),
    on(AppActions.GetFolderContentSuccess, (state, action) => {
        let res = {...state?.foldersContent.data};
        const folder = action.payload.folder.data;
        const query = {...state?.currentSelectionCriteria?.data?.query};
        const activeGroup = action.payload.activeGroup;
        const id = action?.payload?.columnValue || action?.payload?.folderValueId || folder.onqFolder?.onqFolderId;
        let selectionData = {};
        if (query && query?.groupList && query?.groupList?.length) {
            query.groupList.forEach(group => {
                if (group.id === activeGroup && group?.setList && group?.setList?.length) {
                    group?.setList?.forEach(set => {
                        if (set.folderId === id) {
                            selectionData = { selection: set };
                        }
                    });
                }
            });
        }

        let filteredIndividualBehaviors = {...state?.filtratedIndividualBehaviors};
        let filteredProductBehaviors = {...state?.filtratedProductBehaviors};
        if (folder?.behaviorListTooManyResults) {
            if (filteredIndividualBehaviors?.[activeGroup]) {
                filteredIndividualBehaviors = {...filteredIndividualBehaviors, [activeGroup]: {...filteredIndividualBehaviors?.[activeGroup], [id]: {data: null, loading: false, tooManyResults: true}}};
            } else {
                filteredIndividualBehaviors = {...filteredIndividualBehaviors, [activeGroup]: {[id]: {data: null, loading: false, tooManyResults: true}}};
            }
        }
        if (folder?.behaviorProductListTooManyResults) {
            if (filteredProductBehaviors?.[activeGroup]) {
                filteredProductBehaviors = {...filteredProductBehaviors, [activeGroup]: {...filteredProductBehaviors?.[activeGroup], [id]: {data: null, loading: false, tooManyResults: true}}};
            } else {
                filteredProductBehaviors = {...filteredProductBehaviors, [activeGroup]: {[id]: {data: null, loading: false, tooManyResults: true}}};
            }
        }

        res = {...res, [activeGroup]: {...res[activeGroup], [id]: {...folder, ...selectionData, loading: false, initialLoaded: true}}};
        return {
            ...state,
            foldersContent: {
                loading: false,
                data: res,
            },
            filtratedIndividualBehaviors: filteredIndividualBehaviors || null,
            filtratedProductBehaviors: filteredProductBehaviors || null
        };
    }),
    on(AppActions.GetFolderContentError, (state, action) => {
        let res = {...state?.foldersContent?.data};
        const onqFolderId = action?.folderId;
        const activeGroup = action?.activeGroup;
        if (res?.[activeGroup]?.[onqFolderId]) {
            res = {...res, [activeGroup]: {...res[activeGroup], [onqFolderId]: {}}};
        }
        return {
            ...state,
            foldersContent: {
                loading: false,
                data: res
            }
        };
    }),
    on(AppActions.GetGeoReport, (state) => {
        return {
            ...state,
            geoReport: {
                loading: true,
                data: state.geoReport?.data
            }
        };
    }),
    on(AppActions.GetGeoReportSuccess, (state, action) => {
        return {
            ...state,
            geoReport: {
                loading: false,
                data: action.payload.data,
            }
        };
    }),
    on(AppActions.GetGeoReportError, (state) => {
        return {
            ...state,
            geoReport: {
                loading: false,
                data: state.geoReport?.data
            }
        };
    }),
    on(AppActions.UpdateCrosshatchReport, (state) => {
        return {
            ...state,
            crosshatchReport: {
                loading: true,
                data: state.crosshatchReport?.data
            }
        };
    }),
    on(AppActions.UpdateCrosshatchReportSuccess, (state, action) => {
        return {
            ...state,
            crosshatchReport: {
                loading: false,
                data: action.payload.data,
            }
        };
    }),
    on(AppActions.UpdateCrosshatchReportError, (state) => {
        return {
            ...state,
            crosshatchReport: {
                loading: false,
                data: state.crosshatchReport?.data
            }
        };
    }),
    on(AppActions.ClearCrosshatchReport, (state) => {
        return {
            ...state,
            crosshatchReport: {
                loading: false,
                data: null
            }
        };
    }),
    on(AppActions.ClearExistingDeploymentsResults, (state) => {
        return {
            ...state,
            searchExistingDeploymentsResult: {
                loading: false,
                data: {
                    deployments: []
                }
            }
        };
    }),
    on(AppActions.GetBulkContactRules, (state) => {
        return {
            ...state,
            contactRulesLHS: {
                loading: true,
                etag: state.contactRulesLHS.etag,
                data: state.contactRulesLHS?.data
            }
        };
    }),
    on(AppActions.GetBulkContactRulesSuccess, (state, action) => {
        return {
            ...state,
            contactRulesLHS: {
                loading: false,
                etag: action.payload.etag,
                data: action.payload.data
            }
        };
    }),
    on(AppActions.GetBulkContactRulesError, (state) => {
        return {
            ...state,
            contactRulesLHS: {
                loading: false,
                etag: state.contactRulesLHS.etag,
                data: state.contactRulesLHS?.data
            }
        };
    }),
    on(AppActions.GetSessionContactRules, (state) => {
        return {
            ...state,
            sessionContactRules: {
                loading: true,
                data: []
            }
        };
    }) ,
    on(AppActions.GetSessionContactRulesSuccess, (state, action) => {
        return {
            ...state,
            sessionContactRules: {
                loading: false,
                data: action.payload.data
            }
        };
    }),
    on(AppActions.GetSessionContactRulesError, (state) => {
        return {
            ...state,
            sessionContactRules: {
                loading: false,
                data: []
            }
        };
    }),
    on(AppActions.ClearSelectedFolders, (state) => {
        return {
            ...state,
            foldersContent: {
                loading: false,
                data: null
            }
        };
    }),
    on(AppActions.SetSelectionCriteria, (state, action) => {
        let folderContentData = {...state?.foldersContent.data};
        let query = {...action?.payload?.selectionCriteria?.query};
        const activeGroup = query?.activeGroup;
        const oldQuery = {...state?.currentSelectionCriteria?.data?.query};

        if (oldQuery.status == 'saved') {
            const hasGroupListChanged = !areGroupListsEqual(query?.groupList || [], oldQuery?.groupList || []) || true;
            query = {...query, status: hasGroupListChanged ? query.status : 'saved'};
        }
        const selectionCriteria = {...action?.payload?.selectionCriteria, query};

        const oldSetList = [];
        if (oldQuery && oldQuery?.groupList && oldQuery?.groupList?.length) {
            oldQuery?.groupList?.forEach(group => {
                if (group.id === query?.activeGroup && group?.setList && group?.setList?.length) {
                    group?.setList?.forEach(set => {
                        oldSetList.push(set);
                    });
                }
            });
        }

        if (selectionCriteria?.onqFolderId) {
            let selectionData = folderContentData[activeGroup][selectionCriteria?.onqFolderId]?.selection || {};
            if (query && query.groupList && query.groupList.length) {
                query.groupList.forEach(group => {
                    if (group.id === query.activeGroup) {
                        if (group.setList && group.setList.length) {
                            group.setList.forEach(set => {
                                if (set.folderId === action.payload.selectionCriteria?.onqFolderId) {
                                    if (selectionCriteria?.folderValueId && set?.setItemList?.length) {
                                        set?.setItemList?.filter(item => { return item?.folderValueId !== selectionCriteria?.folderValueId; });
                                    }
                                    selectionData = { selection: set };
                                }
                            });
                            if (oldSetList && group.setList && oldSetList?.length > group.setList?.length) {
                                oldSetList?.forEach(set => {
                                    if (!group.setList?.find(currentSet => currentSet.folderId === set.folderId)) {
                                        folderContentData = {...folderContentData, [activeGroup]: {...folderContentData[activeGroup], [set.folderId]: {...folderContentData[activeGroup][set.folderId], selection: null, loading: false, initialLoaded: false}}};
                                    }
                                });
                            }
                        } else {
                            selectionData = { selection: null };
                        }
                    }
                });
            }

            folderContentData = {...folderContentData, [activeGroup]: {...folderContentData[activeGroup], [selectionCriteria?.onqFolderId]: {...folderContentData[activeGroup][selectionCriteria?.onqFolderId], ...selectionData, loading: false, initialLoaded: false}}};
            return {
                ...state,
                foldersContent: {
                    loading: false,
                    data: folderContentData,
                },
                currentSelectionCriteria: {
                    loading: false,
                    data: {...selectionCriteria, ignoreFolderReload: true}
                },
                calculatedCopyCounts: null
            };
        }

        return {
            ...state,
            currentSelectionCriteria: {
                loading: false,
                data: {...selectionCriteria, ignoreFolderReload: true}
            },
            calculatedCopyCounts: null
        };
    }),

    on(AppActions.OpenSelectionCriteria, (state) => {
        return {
            ...state,
            currentSelectionCriteria: {
                loading: true,
                data: null
            },
            queryLoader: true
        };
    }),
    on(AppActions.OpenSelectionCriteriaSuccess, (state, action) => {
        return handleSelectionCriteriaSuccess(state, action);
    }),
    on(AppActions.OpenSelectionCriteriaError, (state) => {
        return {
            ...state,
            currentSelectionCriteria: {
                loading: false,
                data: state.currentSelectionCriteria.data || null
            },
            queryLoader: false
        };
    }),
    on(AppActions.GetSelectionCriteria, (state) => {
        return {
            ...state,
            currentSelectionCriteria: {
                loading: true,
                data: state.currentSelectionCriteria.data || null
            },
            queryLoader: true,
            foldersContent: {
                loading: false,
                data: null
            }
        };
    }),
    on(AppActions.GetSelectionCriteriaSuccess, (state, action) => {
        return handleSelectionCriteriaSuccess(state, action);
    }),
    on(AppActions.GetSelectionCriteriaError, (state) => {
        return {
            ...state,
            currentSelectionCriteria: {
                loading: false,
                data: state?.currentSelectionCriteria?.data
            },
            queryLoader: false,
            foldersContent: {
                loading: false,
                data: state?.foldersContent?.data
            }
        };
    }),
    on(AppActions.SaveSelectionCriteria, (state) => {
        return {
            ...state,
            currentSelectionCriteria: {
                loading: true,
                data: state.currentSelectionCriteria.data || null
            }
        };
    }),
    on(AppActions.SaveSelectionCriteriaSuccess, (state, action) => {
        return {
            ...state,
            currentSelectionCriteria: {
                loading: false,
                data: {...action.payload?.data, ignoreFolderReload: true}
            }
        };
    }),
    on(AppActions.SaveSelectionCriteriaError, (state) => {
        return {
            ...state,
            currentSelectionCriteria: {
                loading: false,
                data: state?.currentSelectionCriteria?.data
            }
        };
    }),
    on(AppActions.GetQueryUnits, (state) => {
        return {
            ...state,
            queryUnits: {
                loading: true,
                data: state?.queryUnits?.data
            }
        };
    }),
    on(AppActions.GetQueryUnitsSuccess, (state, action) => {
        return {
            ...state,
            queryUnits: {
                loading: false,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetQueryUnitsError, (state) => {
        return {
            ...state,
            queryUnits: {
                loading: false,
                data: state?.queryUnits?.data
            }
        };
    }),
    on(AppActions.ReloadSelectionCriteria, (state) => {
        return {
            ...state,
            currentSelectionCriteria: {
                loading: true,
                data: state.currentSelectionCriteria.data
            },
            currentFolders: {
                loading: true,
                data: state.currentFolders.data
            }
        };
    }),
    // on(AppActions.ReloadSelectionCriteriaSuccess, (state, action) => {
    //     return {
    //         ...state,
    //         currentSelectionCriteria: {
    //             loading: false,
    //             data: state.currentSelectionCriteria.data
    //         },
    //         currentFolders: {
    //             loading: false,
    //             data: state.currentFolders.data
    //         }
    //     };
    // }),
    on(AppActions.ChangeQueryUnitGroup, (state) => {
        return {
            ...state,
            queryUnits: {
                loading: false,
                data: null
            },
            queryLoader: true
        };
    }),
    on(AppActions.ChangeQueryUnitGroupSuccess, (state, action) => {
        let units = [];
        if(action.payload?.data?.unitGroupList && action.payload?.data?.unitGroupList.length > 0) {
            units = action.payload?.data?.unitGroupList;
        }
        return {
            ...state,
            queryUnits: {
                loading: false,
                data: units
            },
            queryLoader: false
        };
    }),
    on(AppActions.ChangeQueryUnitGroupError, (state) => {
        return {
            ...state,
            queryUnits: {
                loading: false,
                data: null
            },
            queryLoader: false
        };
    }),
    on(AppActions.GetSelectionCriteriaText, (state) => {
        return {
            ...state,
            currentSelectionCriteriaText: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.GetSelectionCriteriaTextSuccess, (state, action) => {
        return {
            ...state,
            currentSelectionCriteriaText: {
                loading: false,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetSelectionCriteriaTextError, (state) => {
        return {
            ...state,
            currentSelectionCriteriaText: {
                loading: false,
                data: null
            }
        };
    }),
    on(AppActions.GetSelectionCriteriaHistory, (state) => {
        return {
            ...state,
            currentSelectionCriteriaHistory: {
                loading: true,
                data: []
            }
        };
    }),
    on(AppActions.GetSelectionCriteriaHistorySuccess, (state, action) => {
        return {
            ...state,
            currentSelectionCriteriaHistory: {
                loading: false,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetSelectionCriteriaHistoryError, (state) => {
        return {
            ...state,
            currentSelectionCriteriaHistory: {
                loading: false,
                data: []
            }
        };
    }),
    on(AppActions.NewQuery, (state) => {
        return {
            ...state,
            queryLoader: true
        };
    }),
    on(AppActions.NewQuerySuccess, (state) => {
        let foldersContent = {...state?.foldersContent.data};
        if (foldersContent) {
            Object.keys(foldersContent).forEach(key => {
                Object.keys(foldersContent[key]).forEach(innerKey => {
                    foldersContent = {...foldersContent, [key]: {...foldersContent[key], [innerKey]: {...foldersContent[key][innerKey], selection: null, loading: false, initialLoaded: false}}};
                });
            });
        }
        return {
            ...state,
            currentSelectionCriteria: {
                loading: false,
                data: null
            },
            foldersContent: {
                loading: false,
                data: foldersContent
            },
            calculatedCopyCounts: null,
            queryLoader: false,
            selectedInventoryCalendarDay: null,
        };
    }),
    on(AppActions.NewQueryError, (state) => {
        return {
            ...state,
            queryLoader: false
        };
    }),
    on(AppActions.GetSelectionCriterias, (state) => {
        return {
            ...state,
            selectionCriterias: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.GetSelectionCriteriasSuccess, (state, action) => {
        return {
            ...state,
            selectionCriterias: {
                loading: false,
                data: action.payload.data
            }
        };
    }),
    on(AppActions.GetSelectionCriteriasError, (state) => {
        return {
            ...state,
            selectionCriterias: {
                loading: false,
                data: state.selectionCriterias.data
            }
        };
    }),
    on(AppActions.GetSelectionCriteriaDetails, (state) => {
        return {
            ...state,
            saveQueryLoader: true
        };
    }),
    on(AppActions.GetSelectionCriteriaDetailsSuccess, (state, action) => {
        return {
            ...state,
            currentSelectionCriteria: {
                loading: false,
                data: {...state.currentSelectionCriteria.data, keyword: action.payload?.data?.keyword || '', ignoreFolderReload: true},
            },
            saveQueryLoader: false,
        };
    }),
    on(AppActions.GetSelectionCriteriaDetailsError, (state) => {
        return {
            ...state,
            saveQueryLoader: false
        };
    }),
    on(AppActions.RenameGroupSuccess, (state, action) => {
        return {
            ...state,
            currentSelectionCriteria: {
                loading: false,
                data: {
                    ...action.payload.data,
                    ignoreFolderReload: true
                }
            }
        };
    }),
    on(AppActions.GetGroupDeploymentTypes, (state, action) => {
        const groupDeploymentTypesMap = Object.assign({}, state.groupDeploymentTypes);

        if (groupDeploymentTypesMap[action.payload.databaseId]) {
            groupDeploymentTypesMap[action.payload.databaseId] = [...groupDeploymentTypesMap[action.payload.databaseId]];
        } else {
            groupDeploymentTypesMap[action.payload.databaseId] = null;
        }
        return {
            ...state,
            groupDeploymentTypes: {
                data: state?.groupDeploymentTypes?.data
            }
        };
    }),
    on(AppActions.GetGroupDeploymentTypesSuccess, (state, action) => {
        const groupDeploymentTypesMap = Object.assign({}, state.groupDeploymentTypes);
        groupDeploymentTypesMap[action.payload.databaseId] = [...action.payload.deploymentTypes] ;
        return {
            ...state,
            groupDeploymentTypes: {
                data: {...state?.groupDeploymentTypes?.data, ...groupDeploymentTypesMap}
            }
        };
    }),
    on(AppActions.GetGroupDeploymentTypesError, (state, action) => {
        const groupDeploymentTypesMap = Object.assign({}, state.groupDeploymentTypes);
        groupDeploymentTypesMap[action.payload.databaseId] = null;

        return {
            ...state,
            groupDeploymentTypes: {
                data: state?.groupDeploymentTypes?.data
            }
        };
    }),
    on(AppActions.AudienceOverlapReport, (state) => {
        return {
            ...state,
            audienceOverlapReport: {
                loading: true,
                data: state.audienceOverlapReport?.data
            }
        };
    }),
    on(AppActions.AudienceOverlapReportSuccess, (state, action) => {
        const overlapDataList = [];
        if (action.payload?.data?.overlapDataList?.length > 0) {
            action.payload?.data?.overlapDataList?.forEach(overlapData => {
                overlapDataList.push({
                    name: overlapData?.name?.replace(/,/g, ''),
                    overlap: overlapData?.overlap
                });
            });
        }
        return {
            ...state,
            audienceOverlapReport: {
                loading: false,
                data: {...action.payload?.data, formattedOverlapDataList: overlapDataList}
            }
        };
    }),
    on(AppActions.AudienceOverlapReportError, (state) => {
        return {
            ...state,
            audienceOverlapReport: {
                loading: false,
                data: state.audienceOverlapReport?.data
            }
        };
    }),
    on(AppActions.ClearAudienceOverlap, (state) => {
        return {
            ...state,
            audienceOverlapReport: {
                loading: false,
                data: null
            }
        };
    }),
    on(AppActions.GetIssuesList, (state) => {
        return {
            ...state,
            issueList: {
                loading: false,
                data: []
            }
        };
    }),
    on(AppActions.GetIssuesListSuccess, (state, action) => {
        return {
            ...state,
            issueList: {
                loading: false,
                data: action.payload
            }
        };
    }),
    on(AppActions.GetIssuesListError, (state) => {
        return {
            ...state,
            issueList: {
                loading: false,
                data: state.issueList?.data
            }
        };
    }),
    on(AppActions.GetDemoList, (state) => {
        return {
            ...state,
            demographicList: {
                loading: true,
                data: state.demographicList?.data
            },
            selectedDemographicList: {
                loading: false,
                data: []
            }
        };
    }),
    on(AppActions.GetDemoListSuccess, (state, action) => {
        return {
            ...state,
            demographicList: {
                loading: false,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetDemoListError, (state) => {
        return {
            ...state,
            demographicList: {
                loading: false,
                data: state.demographicList?.data
            }
        };
    }),
    on(AppActions.LoadDemoReportCriteria, (state) => {
        return {
            ...state,
            selectedDemographicList: {
                loading: true,
                data: state.selectedDemographicList?.data
            }
        };
    }),
    on(AppActions.LoadDemoReportCriteriaSuccess, (state, action) => {
        return {
            ...state,
            selectedDemographicList: {
                loading: false,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.LoadDemoReportCriteriaError, (state) => {
        return {
            ...state,
            selectedDemographicList: {
                loading: false,
                data: state.selectedDemographicList?.data
            }
        };
    }),
    on(AppActions.GetSavedDemoList, (state) => {
        return {
            ...state,
            savedReportCriteriaList: {
                loading: true,
                data: state.savedReportCriteriaList?.data
            }
        };
    }),
    on(AppActions.GetSavedDemoListSuccess, (state, action) => {
        return {
            ...state,
            savedReportCriteriaList: {
                loading: false,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetSavedDemoListError, (state) => {
        return {
            ...state,
            savedReportCriteriaList: {
                loading: false,
                data: state.savedReportCriteriaList?.data
            }
        };
    }),
    on(AppActions.GenerateDemoReport, (state) => {
        return {
            ...state,
            generatedDemographicReport: {
                loading: true,
                data: state.generatedDemographicReport?.data
            }
        };
    }),
    on(AppActions.GenereateDemoReportSuccess, (state, action) => {
        return {
            ...state,
            generatedDemographicReport: {
                loading: false,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GenereateDemoReportError, (state, error) => {
        return {
            ...state,
            generatedDemographicReport: {
                loading: false,
                data: state.generatedDemographicReport?.data,
                errors: error
            }
        };
    }),
    on(AppActions.ClearDemoReport, (state) => {
        return {
            ...state,
            generatedDemographicReport: {
                loading: false,
                data: null
            },
            savedReportCriteriaList: {
                loading: false,
                data: null
            }
        };
    }),
    on(AppActions.GetInventoryCalendarData, (state) => {
        return {
            ...state,
            inventoryCalendarData: {
                loading: true,
                data: state.inventoryCalendarData?.data
            },
            inventoryCalendarStatus: {
                loading: true,
                data: state.inventoryCalendarStatus?.data
            }
        };
    }),
    on(AppActions.GetInventoryCalendarRequired, (state) => {
        return {
            ...state,
            inventoryCalendarData: {
                loading: true,
                data: state.inventoryCalendarData?.data
            },
            inventoryCalendarStatus: {
                loading: true,
                data: state.inventoryCalendarStatus?.data
            }
        };
    }),
    on(AppActions.GetInventoryCalendarDataSuccess, (state, action) => {
        return {
            ...state,
            inventoryCalendarData: {
                loading: false,
                data: action.payload?.data
            },
            inventoryCalendarStatus: {
                loading: false,
                data: state.inventoryCalendarStatus?.data
            }
        };
    }),
    on(AppActions.GetInventoryCalendarDataError, (state, error) => {
        return {
            ...state,
            inventoryCalendarData: {
                loading: false,
                data: state.inventoryCalendarData?.data,
                errors: error
            },
            inventoryCalendarStatus: {
                loading: false,
                data: state.inventoryCalendarStatus?.data
            }
        };
    }),
    on(AppActions.GetInventoryCalendarStatus, (state) => {
        return {
            ...state,
            inventoryCalendarStatus: {
                loading: !state.inventoryCalendarStatus?.data ? true : false,
                data: state.inventoryCalendarStatus?.data
            }
        };
    }),
    on(AppActions.GetInventoryCalendarStatusSuccess, (state, action) => {
        return {
            ...state,
            inventoryCalendarStatus: {
                loading: false,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetInventoryCalendarStatusError, (state) => {
        return {
            ...state,
            inventoryCalendarStatus: {
                loading: false,
                data: state.inventoryCalendarStatus?.data,
            }
        };
    }),
    on(AppActions.SelectInventoryCalendarDate, (state) => {
        return {
            ...state,
            selectedInventoryCalendarDay: {
                loading: true,
                data: state.selectedInventoryCalendarDay?.data
            }
        };
    }),
    on(AppActions.SelectInventoryCalendarDateSuccess, (state, action) => {
        let data = null;
        if (action.payload?.data?.id) {
            data = action.payload?.data;
        }
        return {
            ...state,
            selectedInventoryCalendarDay: {
                loading: false,
                data
            }
        };
    }),
    on(AppActions.SelectInventoryCalendarDateError, (state) => {
        return {
            ...state,
            selectedInventoryCalendarDay: {
                loading: false,
                data: null,
            }
        };
    }),
    on(AppActions.InventoryCalendarClose, (state) => {
        return {
            ...state,
            selectedInventoryCalendarDay: {
                loading: true,
                data: state.selectedInventoryCalendarDay?.data
            }
        };
    }),
    on(AppActions.InventoryCalendarCloseSuccess, (state) => {
        return {
            ...state,
            selectedInventoryCalendarDay: {
                loading: false,
                data: null
            }
        };
    }),
    on(AppActions.InventoryCalendarCloseError, (state) => {
        return {
            ...state,
            selectedInventoryCalendarDay: {
                loading: false,
                data: state.selectedInventoryCalendarDay?.data,
            }
        };
    }),
    on(AppActions.CalculateCounts, (state, action) => {
        return {
            ...state,
            calculatedCopyCounts: {
                productId: action.payload?.productId,
                copyCount: null
            }
        };
    }),
    on(AppActions.CalculateCountsSuccess, (state, action) => {
        return {
            ...state,
            calculatedCopyCounts: {
                productId: state.calculatedCopyCounts?.productId,
                copyCount: action.payload.copyCount
            }
        };
    }),
    on(AppActions.CalculateCountsError, (state) => {
        return {
            ...state,
            calculatedCopyCounts: state.calculatedCopyCounts
        };
    }),
    on(AppActions.RefreshCountsSuccess, (state) => {
        return {
            ...state,
            calculatedCopyCounts: null
        };
    }),
    on(AppActions.SearchLibraryFolders, (state) => {
        return {
            ...state,
            searchedLibraryFolders: {
                loading: true,
                data: state.searchedLibraryFolders?.data
            }
        };
    }),
    on(AppActions.SearchLibraryFoldersSuccess, (state, action) => {
        return {
            ...state,
            searchedLibraryFolders: {
                loading: false,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.SearchLibraryFoldersError, (state) => {
        return {
            ...state,
            searchedLibraryFolders: {
                loading: false,
                data: state.searchedLibraryFolders?.data
            }
        };
    }),
    on(AppActions.ClearFolderSearch, (state) => {
        return {
            ...state,
            searchedLibraryFolders: {
                loading: false,
                data: null
            }
        };
    }),
    on(AppActions.GetOnqFolderGroups, (state) => {
        return {
            ...state,
            onqFolderGroups: {
                loading: true,
                data: state.onqFolderGroups?.data
            }
        };
    }),
    on(AppActions.GetOnqFolderGroupsSuccess, (state, action) => {
        return {
            ...state,
            onqFolderGroups: {
                loading: false,
                data: action?.payload?.data
            }
        };
    }),
    on(AppActions.GetOnqFolderGroupsError, (state) => {
        return {
            ...state,
            onqFolderGroups: {
                loading: false,
                data: state.onqFolderGroups?.data
            }
        };
    }),
    on(AppActions.DestroyAvailableOnqFolders, (state) => {
        return {
            ...state,
            availableFolders: {
                loading: false,
                data: null
            }
        };
    }),
    on(AppActions.SetSearchedFolderMarker, (state, action) => {
        if (state.currentSelectionCriteria?.data?.isIssueBuildOn && state.issueBuilderFoldersMap?.data) {
            const spreadMarkedFolders = {...action.payload.markedFolders};
            const ibFoldersMap: Map<string, boolean> = new Map(Object?.entries(state?.issueBuilderFoldersMap?.data));

            function updateColorsIterative(rootFolder: LibraryFolder): LibraryFolder {
                if (!rootFolder) return rootFolder;

                const result = { ...rootFolder };
                const queue: Array<{ source: LibraryFolder; target: LibraryFolder }> = [
                    { source: rootFolder, target: result }
                ];

                while (queue.length > 0) {
                    const current = queue.shift();
                    if (!current) continue;

                    const { source, target } = current;

                    if (source.onqFolderId) {
                        const folderId = source.onqFolderId.toString();
                        if (ibFoldersMap.has(folderId)) {
                            target.issueBuildColorOn = ibFoldersMap.get(folderId);
                        }
                    }

                    if (source.folderList && source.folderList.length > 0) {
                        target.folderList = [];

                        for (let i = 0; i < source.folderList.length; i++) {
                            const childSource = source.folderList[i];
                            const childTarget = { ...childSource };
                            target.folderList.push(childTarget);

                            queue.push({ source: childSource, target: childTarget });
                        }
                    }
                }

                return result;
            }

            const result = updateColorsIterative(spreadMarkedFolders);

            return {
                ...state,
                currentFolders: {
                    loading: state?.currentFolders?.loading,
                    data: result
                }
            };
        } else {
            return {
                ...state,
                currentFolders: {
                    loading: state?.currentFolders?.loading,
                    data: action?.payload?.markedFolders
                }
            };
        }
    }),
    on(AppActions.AddNewFolder, (state) => {
        return {
            ...state,
            newFolderData: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.AddNewFolderSuccess, (state, action) => {
        return {
            ...state,
            newFolderData: {
                loading: false,
                data: action?.payload?.data
            }
        };
    }),
    on(AppActions.AddNewFolderError, (state) => {
        return {
            ...state,
            newFolderData: {
                loading: false,
                data: null
            }
        };
    }),
    on(AppActions.GetSelectedOnqFolders, (state) => {
        return {
            ...state,
            selectedOnqFolders: {
                loading: true,
                data: state.selectedOnqFolders?.data
            }
        };
    }),
    on(AppActions.GetSelectedOnqFoldersSuccess, (state, action) => {
        return {
            ...state,
            selectedOnqFolders: {
                loading: false,
                data: action?.payload?.data
            }
        };
    }),
    on(AppActions.GetSelectedOnqFoldersError, (state) => {
        return {
            ...state,
            selectedOnqFolders: {
                loading: false,
                data: state.selectedOnqFolders?.data
            }
        };
    }),
    on(AppActions.GetOnqContactRules, (state) => {
        return {
            ...state,
            onqContactRules: {
                loading: true,
                data: state.onqContactRules?.data
            }
        };
    }),
    on(AppActions.GetOnqContactRulesSuccess, (state, action) => {
        return {
            ...state,
            onqContactRules: {
                loading: false,
                data: action?.payload?.data
            }
        };
    }),
    on(AppActions.GetOnqContactRulesError, (state) => {
        return {
            ...state,
            onqContactRules: {
                loading: false,
                data: state.onqContactRules?.data
            }
        };
    }),
    on(AppActions.GetOnqHistory, (state) => {
        return {
            ...state,
            onqHistory: {
                loading: true,
                data: state.onqHistory?.data
            }
        };
    }),
    on(AppActions.GetOnqHistorySuccess, (state, action) => {
        return {
            ...state,
            onqHistory: {
                loading: false,
                data: action?.payload?.data
            }
        };
    }),
    on(AppActions.GetOnqHistoryError, (state) => {
        return {
            ...state,
            onqHistory: {
                loading: false,
                data: state.onqHistory?.data
            }
        };
    }),
    on(AppActions.GetOnqSegments, (state) => {
        return {
            ...state,
            onqSegments: {
                loading: true,
                data: state.onqSegments?.data
            }
        };
    }),
    on(AppActions.GetOnqSegmentsSuccess, (state, action) => {
        return {
            ...state,
            onqSegments: {
                loading: false,
                data: action?.payload?.data
            }
        };
    }),
    on(AppActions.GetOnqSegmentsError, (state) => {
        return {
            ...state,
            onqSegments: {
                loading: false,
                data: state.onqSegments?.data
            }
        };
    }),
    on(AppActions.GetOnqSelectedSegments, (state) => {
        return {
            ...state,
            onqSelectedSegments: {
                loading: true,
                data: state.onqSelectedSegments?.data
            }
        };
    }),
    on(AppActions.GetOnqSelectedSegmentsSuccess, (state, action) => {
        return {
            ...state,
            onqSelectedSegments: {
                loading: false,
                data: action?.payload?.data
            }
        };
    }),
    on(AppActions.GetOnqSelectedSegmentsError, (state) => {
        return {
            ...state,
            onqSelectedSegments: {
                loading: false,
                data: state.onqSelectedSegments?.data
            }
        };
    }),
    on(AppActions.GetAvailableFolderChildren, (state) => {
        return {
            ...state,
            availableFolderChildren: {
                loading: true,
                data: null
            }
        };
    }),
    on(AppActions.GetAvailableFolderChildrenSuccess, (state, action) => {
        return {
            ...state,
            availableFolderChildren: {
                loading: false,
                data: action?.payload?.data
            }
        };
    }),
    on(AppActions.GetAvailableFolderChildrenError, (state) => {
        return {
            ...state,
            availableFolderChildren: {
                loading: false,
                data: null
            }
        };
    }),
    on(AppActions.SaveOnqCurrentFolders, (state) => {
        return {
            ...state,
            currentFolders: {
                loading: true,
                data: state?.currentFolders?.data
            }
        };
    }),
    on(AppActions.SaveOnqCurrentFoldersSuccess, (state, action) => {
        return {
            ...state,
            currentFolders: {
                loading: false,
                data: action?.payload?.data
            }
        };
    }),
    on(AppActions.SaveOnqCurrentFoldersError, (state) => {
        return {
            ...state,
            currentFolders: {
                loading: false,
                data: state?.currentFolders?.data
            }
        };
    }),
    on(AppActions.SaveOnqOutputFolders, (state) => {
        return {
            ...state,
            onqOutputFoldersResult: {
                ...state?.onqOutputFoldersResult,
                loading: true,
            },
            currentFolders: {
                loading: true,
                data: state?.currentFolders?.data
            }
        };
    }),
    on(AppActions.SaveOnqOutputFoldersSuccess, (state) => {
        return {
            ...state,
            onqOutputFoldersResult: {
                ...state?.onqOutputFoldersResult,
                loading: false,
            },
            currentFolders: {
                loading: false,
                data: state?.currentFolders?.data
            }
        };
    }),
    on(AppActions.SaveOnqOutputFoldersError, (state) => {
        return {
            ...state,
            onqOutputFoldersResult: {
                ...state?.onqOutputFoldersResult,
                loading: false,
            },
            currentFolders: {
                loading: false,
                data: state?.currentFolders?.data
            }
        };
    }),
    on(AppActions.SaveOnqMatchEvaluation, (state) => {
        return {
            ...state,
            onqMatchEvalResult: {
                ...state?.onqMatchEvalResult,
                loading: true,
            }
        };
    }),
    on(AppActions.SaveOnqMatchEvaluationSuccess, (state) => {
        return {
            ...state,
            onqMatchEvalResult: {
                ...state?.onqMatchEvalResult,
                loading: false,
            }
        };
    }),
    on(AppActions.SaveOnqMatchEvaluationError, (state) => {
        return {
            ...state,
            onqMatchEvalResult: {
                ...state?.onqMatchEvalResult,
                loading: false,
            }
        };
    }),
    on(AppActions.SaveOnqReports, (state) => {
        return {
            ...state,
            onqReportResult: {
                ...state?.onqReportResult,
                loading: true,
            }
        };
    }),
    on(AppActions.SaveOnqReportsSuccess, (state) => {
        return {
            ...state,
            onqReportResult: {
                ...state?.onqReportResult,
                loading: false,
            }
        };
    }),
    on(AppActions.SaveOnqReportsError, (state) => {
        return {
            ...state,
            onqReportResult: {
                ...state?.onqReportResult,
                loading: false,
            }
        };
    }),
    on(AppActions.SaveOnqContactRules, (state) => {
        return {
            ...state,
            onqContactRulesResult: {
                ...state?.onqContactRulesResult,
                loading: true,
            }
        };
    }),
    on(AppActions.SaveOnqContactRulesSuccess, (state) => {
        return {
            ...state,
            onqContactRulesResult: {
                ...state?.onqContactRulesResult,
                loading: false,
            }
        };
    }),
    on(AppActions.SaveOnqContactRulesError, (state) => {
        return {
            ...state,
            onqContactRulesResult: {
                ...state?.onqContactRulesResult,
                loading: false,
            }
        };
    }),
    on(AppActions.GetFolderProductTypes, (state) => {
        return {
            ...state,
            productMembershipTypes: {
                loading: true,
                data: state?.productMembershipTypes?.data
            }
        };
    }),
    on(AppActions.GetFolderProductTypesSuccess, (state, action) => {
        return {
            ...state,
            productMembershipTypes: {
                loading: false,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetFolderProductTypesError, (state) => {
        return {
            ...state,
            productMembershipTypes: {
                loading: false,
                data: state?.productMembershipTypes?.data
            }
        };
    }),
    // on(AppActions.RemoveMatchEvalGroup, (state, action) => {
    //     let targetList;
    //     switch (action.payload.mode) {
    //         case 'external':
    //             targetList = [...state?.currentSelectionCriteria?.data?.query?.matchEvaluationSchema?.externalListSchemaList];
    //             break;
    //         case 'internal':
    //             targetList = [...state?.currentSelectionCriteria?.data?.query?.matchEvaluationSchema?.internalListSchemaList];
    //             break;

    //     }

    //     const currentSelectionCriteria = {...state?.currentSelectionCriteria?.data};
    //     if (currentSelectionCriteria && targetList && targetList.length) {
    //         targetList.splice(action.payload.index, 1);
    //         return {
    //             ...state,
    //             currentSelectionCriteria: {
    //                 loading: false,
    //                 data: {
    //                     ...currentSelectionCriteria,
    //                     query: {
    //                         ...currentSelectionCriteria?.query,
    //                         matchEvaluationSchema: {
    //                             ...currentSelectionCriteria?.query?.matchEvaluationSchema,
    //                             internalListSchemaList: action.payload.mode === 'internal' ? targetList : [],
    //                             externalListSchemaList: action.payload.mode === 'external' ? targetList: []
    //                         }
    //                     }
    //                 }
    //             }
    //         };
    //     }
    //     return {
    //         ...state,
    //         currentSelectionCriteria: {
    //             loading: false,
    //             data: currentSelectionCriteria
    //         }
    //     };
    // }),
    on(AppActions.GetQueryXml, (state) => {
        return {
            ...state,
            queryXml: {
                loading: true,
                data: state?.queryXml?.data
            }
        };
    }),
    on(AppActions.GetQueryXmlSuccess, (state, action) => {
        return {
            ...state,
            queryXml: {
                loading: false,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetQueryXmlError, (state) => {
        return {
            ...state,
            queryXml: {
                loading: false,
                data: state?.queryXml?.data
            }
        };
    }),
    on(AppActions.GetQueryBitsets, (state) => {
        return {
            ...state,
            queryBitsets: {
                loading: true,
                data: state?.queryBitsets?.data
            }
        };
    }),
    on(AppActions.GetQueryBitsetsSuccess, (state,action) => {
        return {
            ...state,
            queryBitsets: {
                loading: false,
                data: action?.payload?.data
            }
        };
    }),
    on(AppActions.GetQueryBitsetsError, (state) => {
        return {
            ...state,
            queryBitsets: {
                loading: false,
                data: state?.queryBitsets?.data
            }
        };
    }),
    on(AppActions.ChangeGroupLogic, (state) => {
        return {
            ...state,
            queryLoader: true
        };
    }),
    on(AppActions.ChangeGroupLogicSuccess, (state, action) => {
        return {
            ...state,
            currentSelectionCriteria: {
                loading: false,
                data: action.payload?.data,
            },
            queryLoader: false
        };
    }),
    on(AppActions.ChangeGroupLogicError, (state) => {
        return {
            ...state,
            queryLoader: false
        };
    }),
    on(AppActions.GetSelectedGroupLogicError, (state) => {
        return {
            ...state,
            currentSelectionCriteria: {
                loading: false,
                ...state.currentSelectionCriteria
            }
        };
    }),
    on(AppActions.GetQueryGroups, (state) => {
        return {
            ...state,
            currentQueryGroups: {
                ...state.currentQueryGroups,
                loading: true,
            }
        };
    }),
    on(AppActions.GetQueryGroupsSuccess, (state, action) => {
        return {
            ...state,
            currentQueryGroups: {
                loading: false,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetQueryGroupsError, (state) => {
        return {
            ...state,
            currentQueryGroups: {
                loading: false,
                ...state.currentQueryGroups
            }
        };
    }),
    on(AppActions.ChangeSetLogic, (state) => {
        return {
            ...state,
            queryLoader: true,
        };
    }),
    on(AppActions.ChangeSetLogicSuccess, (state, action) => {
        return {
            ...state,
            currentSelectionCriteria: {
                loading: false,
                data: {...action.payload?.data, ignoreFolderReload: true},
            },
            queryLoader: false,
        };
    }),
    on(AppActions.ChangeSetLogicError, (state) => {
        return {
            ...state,
            queryLoader: false,
        };
    }),
    on(AppActions.ExpandQueryPath, (state, action) => {
        let currentFoldersData = {...state?.currentFolders.data};
        if (action.payload?.folderIds?.length > 0) {
            for (const folderId of action.payload?.folderIds) {
                const queryFolderPath = state?.currentQueriesPaths?.[folderId];
                if (queryFolderPath && currentFoldersData?.folderList?.length > 0) {
                    currentFoldersData = {...currentFoldersData, expanded: true};

                    // Iterative approach to mark the path
                    const markPathIterative = (rootFolders, pathIndices) => {
                        if (!rootFolders || !pathIndices || pathIndices.length === 0) {
                            return rootFolders;
                        }

                        // Create a deep copy of the folders to avoid mutating the original
                        const result = rootFolders.map(folder => ({ ...folder }));

                        // Start with the first level folders
                        let currentFolders = result;
                        let currentPathIndex = 0;
                        let currentPathValue = pathIndices[currentPathIndex];

                        // Keep track of parent folders that need to be updated
                        const folderStack = [];

                        // Process each level of the path
                        while (currentPathIndex < pathIndices.length) {
                            // Find the folder that matches the current path value
                            const folderIndex = currentFolders.findIndex(f => f?.onqFolderId === currentPathValue);

                            if (folderIndex !== -1) {
                                // Create a copy of the folder and mark it as expanded
                                const updatedFolder = {
                                    ...currentFolders[folderIndex],
                                    expanded: true,
                                    // If it has a folderList, make a deep copy of that too
                                    folderList: currentFolders[folderIndex]?.folderList
                                        ? [...currentFolders[folderIndex].folderList]
                                        : []
                                };

                                // Create a new array for the current level with the updated folder
                                currentFolders = [
                                    ...currentFolders.slice(0, folderIndex),
                                    updatedFolder,
                                    ...currentFolders.slice(folderIndex + 1)
                                ];

                                // Move to the next level if there are child folders
                                if (updatedFolder?.folderList?.length > 0 && currentPathIndex < pathIndices.length - 1) {
                                    // Push the current level to the stack for later updates
                                    folderStack.push({
                                        parentFolders: currentFolders,
                                        folderIndex: folderIndex
                                    });

                                    // Move to the next level
                                    currentFolders = updatedFolder.folderList;
                                    currentPathIndex++;
                                    currentPathValue = pathIndices[currentPathIndex];
                                } else {
                                    // We've reached the end of this path branch
                                    break;
                                }
                            } else {
                                // If we can't find the folder, stop processing this path
                                break;
                            }
                        }

                        // Update parent folders from bottom to top
                        let updatedFolders = currentFolders;
                        while (folderStack.length > 0) {
                            const { parentFolders, folderIndex } = folderStack.pop();

                            // Create a new updated folder with the new folderList
                            const updatedFolder = {
                                ...parentFolders[folderIndex],
                                folderList: updatedFolders
                            };

                            // Create a new array for this level with the updated folder
                            updatedFolders = [
                                ...parentFolders.slice(0, folderIndex),
                                updatedFolder,
                                ...parentFolders.slice(folderIndex + 1)
                            ];
                        }

                        return updatedFolders;
                    };

                    const markedPath = markPathIterative(currentFoldersData?.folderList, queryFolderPath);
                    currentFoldersData = {...currentFoldersData, folderList: markedPath};
                }
            }
        }
        return {
            ...state,
            currentFolders: {
                loading: state?.currentFolders?.loading,
                data: currentFoldersData,
            }
        };
    }),
    on(AppActions.ExpandSelectedFolderPath, (state, action) => {
        let currentFoldersData = action.payload?.currentSelectedFields;
        let mapResult = [];
        if (action.payload?.currentSelectedFields && action.payload?.currentSelectedFields?.children.length) {
            const stack: { folder; path: string }[] = [];
            const findPaths = (root, targetFolderId) => {
                root?.forEach(innerFolder => {
                    stack.push({ folder: innerFolder, path: innerFolder.id });
                });

                while (stack.length > 0) {
                    const { folder, path } = stack.pop() as { folder; path: string };

                    if (folder.id == targetFolderId && path) {
                        return path?.toString()?.split(',');
                    }

                    if (folder.children) {
                        for (const subFolder of folder.children) {
                            const newPath = `${path},${subFolder.id}`;
                            stack.push({ folder: subFolder, path: newPath });
                        }
                    }
                }
                return null;
            };
            mapResult = findPaths(action.payload.currentSelectedFields?.children, action.payload?.folderId);
            if (mapResult && mapResult?.length > 0) {
                const markPath = (mainFolders, index) => {
                    return mainFolders?.map(folder => {
                        if (folder.id == mapResult[index] && index !== mapResult.length - 1) {
                            const updatedFolder = { ...folder, expanded: true };
                            if (updatedFolder?.children?.length > 0) {
                                updatedFolder.children = markPath(updatedFolder.children, index + 1);
                            }

                            return updatedFolder;
                        } else if (folder.id == mapResult[index] && index === mapResult.length - 1) {
                            const updatedFolder = { ...folder, expanded: true, selected: true };
                            return updatedFolder;
                        }
                        return folder;
                    });
                };
                const markedPath = markPath(currentFoldersData?.children, 0);
                if (markedPath) currentFoldersData = {...currentFoldersData, children: markedPath, expanded: true};
            }
        }

        return {
            ...state,
            selectedOnqFolders: {
                loading: false,
                data: {selectedFolderList: [{...currentFoldersData}]},
            }
        };
    }),
    on(AppActions.ShowNthToggle, (state) => {
        return {
            ...state,
            nthGroupsShow: false
        };
    }),
    on(AppActions.ShowNthToggleSuccess, (state) => {
        return {
            ...state,
            nthGroupsShow: true
        };
    }),
    on(AppActions.ShowNthToggleError, (state) => {
        return {
            ...state,
            nthGroupsShow: false
        };
    }),
    on(AppActions.NthGroupsSwitch, (state, action) => {
        return {
            ...state,
            nthGroupsOff: action.payload
        };
    }),
    on(AppActions.GetCrosshatchFields, (state) => {
        return {
            ...state,
            crosshatchReportFields: {
                loading: true,
                data: state.crosshatchReportFields?.data
            }
        };
    }),
    on(AppActions.GetCrosshatchFieldsSuccess, (state, action) => {
        return {
            ...state,
            crosshatchReportFields: {
                loading: false,
                data: action.payload.data,
            }
        };
    }),
    on(AppActions.GetCrosshatchFieldsError, (state) => {
        return {
            ...state,
            crosshatchReportFields: {
                loading: false,
                data: state.crosshatchReportFields?.data
            }
        };
    }),
    on(AppActions.ClearInventoryCalendar, (state) => {
        return {
            ...state,
            selectedInventoryCalendarDay: {
                loading: false,
                data: null
            }
        };
    }),
    on(AppActions.QueryCheckboxContentSearch, (state) => {
        return {
            ...state,
            smallQueryLoader: true,
        };
    }),
    on(AppActions.QueryCheckboxContentSearchSuccess, (state) => {
        return {
            ...state,
            smallQueryLoader: false,
        };
    }),
    on(AppActions.QueryCheckboxContentSearchError, (state) => {
        return {
            ...state,
            smallQueryLoader: false,
        };
    }),
    on(AppActions.ShowQuerySmallLoader, (state) => {
        return {
            ...state,
            smallQueryLoader: true,
        };
    }),
    on(AppActions.HideQuerySmallLoader, (state) => {
        return {
            ...state,
            smallQueryLoader: false,
        };
    }),
    on(AppActions.GetOutputDeploymentTypesSuccess, (state, action) => {
        return {
            ...state,
            outputDeploymentTypes: action.payload,
        };
    }),
    on(AppActions.GetOutputDeploymentTypesError, (state) => {
        return {
            ...state,
            outputDeploymentTypes: {
                loading: false,
                data: state.outputDeploymentTypes?.data
            },
        };
    }),
    on(AppActions.AddSelectionToQuery, (state) => {
        return {
            ...state,
            queryLoader: true,
        };
    }),
    on(AppActions.AddSelectionToQuerySuccess, (state) => {
        return {
            ...state,
            queryLoader: false,
        };
    }),
    on(AppActions.AddSelectionToQueryError, (state) => {
        return {
            ...state,
            queryLoader: false,
        };
    }),
    on(AppActions.GetOutputDeploymentTypes, (state) => {
        return {
            ...state,
            outputDeploymentTypes: {
                loading: true,
                data: state.outputDeploymentTypes?.data
            },
        };
    }),
    on(AppActions.GetOutputDeploymentTypesSuccess, (state, action) => {
        return {
            ...state,
            outputDeploymentTypes: {
                loading: false,
                data: action.payload?.data
            }
        };
    }),
    on(AppActions.GetOutputDeploymentTypesError, (state) => {
        return {
            ...state,
            outputDeploymentTypes: {
                loading: false,
                data: state.outputDeploymentTypes?.data
            },
        };
    }),
    on(AppActions.SaveQuery, (state) => {
        return {
            ...state,
            saveQueryLoader: true,
        };
    }),
    on(AppActions.SaveQuerySuccess, (state) => {
        return {
            ...state,
            saveQueryLoader: false,
        };
    }),
    on(AppActions.SaveQueryError, (state) => {
        return {
            ...state,
            saveQueryLoader: false,
        };
    }),
    on(AppActions.UpdateSelectionCriteria, (state) => {
        return {
            ...state,
            queryLoader: true,
        };
    }),
    on(AppActions.UpdateSelectionCriteriaSuccess, (state, action) => {
        return {
            ...state,
            currentSelectionCriteria: {
                loading: false,
                data: {
                    ...state.currentSelectionCriteria.data,
                    query: {...state.currentSelectionCriteria.data?.query, name: action.payload?.data?.name}
                },
            },
            queryLoader: false
        };
    }),
    on(AppActions.UpdateSelectionCriteriaError, (state) => {
        return {
            ...state,
            queryLoader: false,
        };
    }),
    on(AppActions.ClearSelectedDemographicFields, (state) => {
        return {
            ...state,
            selectedDemographicList: {
                loading: false,
                data: []
            }
        };
    }),

    on(AppActions.GetFiltratedBehaviors, (state, action) => {
        let res = null;
        if (action.payload?.behaviorFilterText) {
            res = {...state?.filtratedIndividualBehaviors};
        }
        if (action.payload?.behaviorProductFilterText) {
            res = {...state?.filtratedProductBehaviors};
        }

        const onqFolderId = action.payload.folder?.columnValue || action.payload.folder?.folderValueId || action.payload.folder?.onqFolderId;
        const activeGroup = action.payload.activeGroup;

        if (res?.[activeGroup]) {
            res = {...res, [activeGroup]: {...res[activeGroup], [onqFolderId]: {data: null, loading: true, tooManyResults: true}}};
        } else {
            res = {...res, [activeGroup]: {[onqFolderId]: {data: null, loading: true, tooManyResults: true}}};
        }

        return {
            ...state,
            filtratedIndividualBehaviors: action?.payload?.behaviorFilterText ? res : state?.filtratedIndividualBehaviors,
            filtratedProductBehaviors: action?.payload?.behaviorProductFilterText ? res : state?.filtratedProductBehaviors,
        };
    }),

    on(AppActions.GetFiltratedIndividualBehaviorsSuccess, (state, action) => {
        let res = {...state?.filtratedIndividualBehaviors};
        const folder = action.payload.folder.data;
        const activeGroup = action.payload.activeGroup;
        const id = action?.payload?.columnValue || action?.payload?.folderValueId || folder.onqFolder?.onqFolderId;

        res = {...res, [activeGroup]: {...res?.[activeGroup], [id]: {data: folder?.behaviorList || [], loading: false, tooManyResults: folder?.behaviorListTooManyResults}}};
        return {
            ...state,
            filtratedIndividualBehaviors: res
        };
    }),

    on(AppActions.GetFiltratedProductBehaviorsSuccess, (state, action) => {
        let res = {...state?.filtratedProductBehaviors};
        const folder = action.payload.folder.data;
        const activeGroup = action.payload.activeGroup;
        const id = action?.payload?.columnValue || action?.payload?.folderValueId || folder.onqFolder?.onqFolderId;

        res = {...res, [activeGroup]: {...res?.[activeGroup], [id]: {data: folder?.behaviorProductList || [], loading: false, tooManyResults: folder?.behaviorListTooManyResults}}};
        return {
            ...state,
            filtratedProductBehaviors: res
        };
    }),

    on(AppActions.GetFiltratedIndividualBehaviorsError, (state, action) => {
        let res = {...state?.filtratedIndividualBehaviors};
        const onqFolderId = action?.folderId;
        const activeGroup = action?.activeGroup;

        res = {...res, [activeGroup]: {...res?.[activeGroup], [onqFolderId]: {data: null, loading: false, tooManyResults: true}}};
        return {
            ...state,
            filtratedIndividualBehaviors: res,
        };
    }),

    on(AppActions.GetFiltratedProductBehaviorsError, (state, action) => {
        let res = {...state?.filtratedProductBehaviors};
        const onqFolderId = action?.folderId;
        const activeGroup = action?.activeGroup;
        res = {...res, [activeGroup]: {...res?.[activeGroup], [onqFolderId]: {data: null, loading: false, tooManyResults: true}}};
        return {
            ...state,
            filtratedProductBehaviors: res,
        };
    }),
    on(AppActions.ClearStoreValue, (state, action) => {
        return {
            ...state,
            [action.key]: {
                loading: false,
                data: null
            }
        };
    }),
    ...foldersReducer
);

export function audienceBuilderReducer(state: AudienceBuilderState | undefined, action: Action) {
    return appReducer(state, action);
}

function handleSelectionCriteriaSuccess(state: AudienceBuilderState, action: {payload: ResponseWrapper<SelectionCriteria>}) {
    let foldersContent = {...state.foldersContent?.data};
    const activeGroup = action?.payload?.data?.query?.activeGroup;
    let newSelectionCriteria = action?.payload?.data;
    const oldSelectionCriteria = state?.currentSelectionCriteria.data;
    const queryStatus = action?.payload?.data?.query?.status;

    if (oldSelectionCriteria && newSelectionCriteria && !newSelectionCriteria.selectionCriteriaId && oldSelectionCriteria.selectionCriteriaId === newSelectionCriteria.query.id) {
        newSelectionCriteria = {
            ...oldSelectionCriteria,
            ...newSelectionCriteria
        };
    }

    if (foldersContent) {
        Object.keys(foldersContent).forEach(key => {
            Object.keys(foldersContent[key]).forEach(innerKey => {
                foldersContent = {...foldersContent, [key]: {...foldersContent[key], [innerKey]: {...foldersContent[key][innerKey], loading: true}}};
                if (newSelectionCriteria?.query?.groupList?.length) {
                    newSelectionCriteria?.query?.groupList?.forEach(group => {
                        if (group?.setList?.length) {
                            group?.setList?.forEach(set => {
                                if (set?.folderId?.toString() === innerKey) {
                                    foldersContent = {...foldersContent, [activeGroup]: {...foldersContent[activeGroup], [innerKey]: {...foldersContent[activeGroup][innerKey], selection: set, loading: false, initialLoaded: true}}};
                                } else {
                                    foldersContent = {...foldersContent, [activeGroup]: {...foldersContent[activeGroup], [innerKey]: {...foldersContent[activeGroup]?.[innerKey], loading: false, initialLoaded: true}}};
                                }
                            });
                        } else {
                            foldersContent = {...foldersContent, [activeGroup]: {...foldersContent[activeGroup], [innerKey]: {...foldersContent[activeGroup][innerKey], loading: false, initialLoaded: true}}};
                        }
                    });
                } else {
                    foldersContent = {...foldersContent, [activeGroup]: {...foldersContent[activeGroup], [innerKey]: {...foldersContent[activeGroup][innerKey], loading: false, initialLoaded: true}}};
                }
            });
        });
    }
    return {
        ...state,
        currentSelectionCriteria: {
            loading: false,
            data: {
                ...newSelectionCriteria,
                ignoreFolderReload: action?.payload?.data?.selectionCriteriaId ? queryStatus !== 'saved' : false
            }
        },
        queryLoader: false,
        foldersContent: {
            loading: false,
            data: foldersContent
        }
    };
}

const areGroupListsEqual = (newQueryGroups: Group[], oldQueryGroups: Group[]): boolean => {
    if (newQueryGroups?.length !== oldQueryGroups?.length) return false;
    for (let i = 0; i < newQueryGroups?.length; i++) {
        const group1 = newQueryGroups[i];
        const group2 = oldQueryGroups?.find(g => g.id === group1.id);
        if (!group2) {
            return false;
        }
        if (group1?.setList?.length !== group2?.setList?.length) {
            return false;
        }
        for (let j = 0; j < group1?.setList?.length; j++) {
            const set1 = group1?.setList[j];
            const set2 = group2?.setList?.find(s => s.id === set1.id);
            if (!set2 || !areSetsEqual(set1, set2)) {
                return false;
            }
        }
    }
    return true;
};

const areSetsEqual = (newSet: Set, oldSet: Set): boolean => {
    return (
        newSet.count === oldSet.count &&
        newSet.folderId === oldSet.folderId &&
        newSet.folderValueId === oldSet.folderValueId &&
        newSet.name === oldSet.name
    );
};
